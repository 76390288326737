import React, { Component } from "react";
import Window from "../tools/Window";
import cx from "classnames";
import AudioPlayer from "react-h5-audio-player";
import "./_styles.scss";
import "react-h5-audio-player/lib/styles.css";
import { cd32 } from "../../icons";
import { WindowProgram } from "packard-belle";
import buildMenu from "../../helpers/menuBuilder";

const playlist = [
  { src: "/static/mp3/Friends.mp3", title: "Friends", duration: "0:43" },
  { src: "/static/mp3/Pokemon.mp3", title: "Pokemon", duration: "3:17" },
];

class Player extends Component {
  static defaultProps = {
    data: {},
  };

  state = {
    playing: false,
    currentTrack: 0, // Track index for the playlist
    data: {
      ...this.props.data,
    },
  };

  togglePlay = () => this.setState((state) => ({ playing: !state.playing }));

  // Handle next track
  handleClickNext = () => {
    this.setState((state) => ({
      currentTrack:
        state.currentTrack < playlist.length - 1 ? state.currentTrack + 1 : 0,
    }));
  };

  // Handle when the track ends
  handleEnd = () => {
    this.setState((state) => ({
      currentTrack:
        state.currentTrack < playlist.length - 1 ? state.currentTrack + 1 : 0,
    }));
  };

  // Handle track selection from the playlist
  selectTrack = (index) => {
    this.setState({ currentTrack: index });
  };

  render() {
    const {
      props,
      state,
      togglePlay,
      handleClickNext,
      handleEnd,
      selectTrack,
    } = this;

    return (
      <>
        <Window
          {...props}
          icon={cd32}
          initialHeight={props.data.height || 400}
          initialWidth={props.data.width || 500}
          minWidth={props.data.width}
          minHeight={props.data.height}
          maximizeOnOpen={props.data.maximizeOnOpen || false}
          footer={[{ text: "Audio player with playlist" }]}
          menuOptions={buildMenu(
            {
              ...props,
              multiInstance: true,
              onPlay: this.togglePlay,
            },
            {
              playback: [
                {
                  title: "Play",
                  onClick: this.togglePlay,
                  className: state.playing ? "checked" : undefined,
                },
              ],
            }
          )}
          className={cx("Player", props.className)}
          title={`Audio Player | Now Playing: ${
            playlist[state.currentTrack].title
          }`}
          Component={WindowProgram}
        >
          <div className="Player__content">
            {/* Audio Player */}
            <AudioPlayer
              volume="0.5"
              src={playlist[state.currentTrack].src}
              showSkipControls={true}
              onClickNext={this.handleClickNext}
              onEnded={this.handleEnd}
              onPlay={this.togglePlay}
              onPause={this.togglePlay}
              onError={() => console.log("play error")}
              header={`Now Playing: ${playlist[state.currentTrack].title}`}
            />

            {/* Playlist View */}
            <div className="Player__playlist">
              <h3>Playlist:</h3>
              <ul>
                {playlist.map((track, index) => (
                  <li
                    key={index}
                    className={cx({
                      "Player__playlist--active": state.currentTrack === index,
                    })}
                    onClick={() => selectTrack(index)}
                  >
                    <span className="Player__playlist__title">
                      {track.title}
                    </span>
                    <span className="Player__playlist__duration">
                      {track.duration}
                    </span>
                    <button className="Player__playlist__play-btn">
                      {state.currentTrack === index ? "⏸️" : "▶️"}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Window>
      </>
    );
  }
}

export default Player;
